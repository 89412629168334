import React from "react"
import { Helmet } from "react-helmet"
import SEO from "~/blocks/Seo/Seo.js"
import "~/blocks/Iframe/Iframe.scss"

const CartoesPage = ({ data }) => {
	return (
		<>
			<Helmet>
				<html className="page page--cartoes" lang="pt-br" />
			</Helmet>
			<SEO
				title="Cartões CantuStore"
				description="Turbine suas compras na Cantu com o exclusivo cartão de crédito PJ CantuStore. Sem anuidade e com compras parceladas em até 12x."
				pathname="/"
			/>
			<iframe className="iframe--100vw iframe--100vh" height="800" width="100%" src="https://cantu.robbin.com.br/"></iframe>
		</>
	)
}

export default CartoesPage;
